import axios from 'axios';

const API_URL = 'http://ec2-52-66-113-159.ap-south-1.compute.amazonaws.com:8080/community-images';
// const API_URL = 'http://localhost:8080/community-images';

export const uploadImage = async (file, customerId, eventId) => {
    const formData = new FormData();
    formData.append('file', file); // Append the file
    formData.append('customerId', customerId); // Append customerId
    formData.append('eventId', eventId); // Append eventId

    console.log('Uploading Image:', {
        file: file.name, // Log file name for verification
        customerId,
        eventId,
    });

    try {
        const response = await axios.post(`${API_URL}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log('Upload Response:', response.data);
        return response.data; // Adjust based on your API response structure
    } catch (error) {
        console.error('Error uploading image:', error.response ? error.response.data : error.message);
        throw error;
    }
};


export const fetchImages = async (eventId) => {
    try {
        const response = await axios.get(`${API_URL}/event/${eventId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching images:', error);
        throw error;
    }
};
