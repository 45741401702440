import React, { useState, useEffect } from 'react';
import { uploadImage, fetchImages } from '../api/api';
import './ImageUpload.css';

const ImageUpload = () => {
    const [file, setFile] = useState(null);
    const [customerId, setCustomerId] = useState('');
    const [eventId, setEventId] = useState('');
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file || !customerId || !eventId) {
            setError('Please provide all inputs');
            return;
        }

        setLoading(true);
        setError('');
        try {
            await uploadImage(file, customerId, eventId);
            alert('Image uploaded successfully!');
            fetchImagesData();
            setFile(null);
        } catch (error) {
            console.error('Upload error:', error);
            setError('Error uploading image');
        } finally {
            setLoading(false);
        }
    };

    const fetchImagesData = async () => {
        try {
            const fetchedImages = await fetchImages(eventId);
            setImages(fetchedImages);
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Error fetching images');
        }
    };

    useEffect(() => {
        if (eventId) {
            fetchImagesData();
        }
    }, [eventId]);

    return (
        <div className="image-upload-container">
            <h2>Image Upload</h2>
            <input
                type="text"
                placeholder="Customer ID"
                value={customerId}
                onChange={(e) => setCustomerId(e.target.value)}
            />
            <input
                type="text"
                placeholder="Event ID"
                value={eventId}
                onChange={(e) => setEventId(e.target.value)}
            />
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleUpload} disabled={loading}>
                {loading ? 'Uploading...' : 'Upload Image'}
            </button>
            {error && <div className="error">{error}</div>}
            <div className="image-grid">
                {images.map((image, index) => (
                    <div key={index} className="image-item">
                        <img src={image.url} alt={`Uploaded ${index}`} className="uploaded-image" />
                    </div>
                ))}
                {file && (
                    <div className="image-item">
                        <img src={URL.createObjectURL(file)} alt="Preview" className="uploaded-image preview" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageUpload;
